import React, { ReactNode } from 'react';
import { ListIndented, ListItem, ListOrdered } from '@marty-js/design/src/atoms/list';
import styled from 'styled-components';
import { HeadingTwo } from './slate/heading-two';
import { HeadingThree } from './slate/heading-three';
import type { ComponentPluginProps, DeepElements } from '../types';
import type { PluginComponent, Node } from './slate/types';
import { escapedNewLineToLineBreakTag } from './slate/utils';
import { Paragraph } from './slate/paragraph';
import { Link } from './slate/link';
import { checkIsBreakable } from './breakableTools';
import Breakable from '../breakable';

export const SLATE_PLUGIN_NAME = 'marty/editor/core/content/slate';

const SlateCell = styled.div<{ nbCol: number }>`
  grid-column-end: span ${(props) => props.nbCol};
`;

const PLUGIN_ELEMENT_MAP: { [type: string]: PluginComponent } = {
  'EMPHASIZE/STRONG': ({ children }) => <strong>{children}</strong>,
  'EMPHASIZE/U': ({ children }) => <u>{children}</u>,
  'EMPHASIZE/STRIKE': ({ children }) => <s>{children}</s>,
  'EMPHASIZE/SMALL': ({ children }) => <small>{children}</small>,
  'EMPHASIZE/EM': ({ children }) => <em>{children}</em>,
  'EMPHASIZE/SUB': ({ children }) => <sub>{children}</sub>,
  'EMPHASIZE/SUP': ({ children }) => <sup>{children}</sup>,
  'CODE/CODE': ({ children }) => <code>{children}</code>,
  'BLOCKQUOTE/BLOCKQUOTE': ({ children }) => <blockquote>{children}</blockquote>,
  'HEADINGS/HEADING-TWO': HeadingTwo,
  'HEADINGS/HEADING-THREE': HeadingThree,
  'HEADINGS/HEADING-FOUR': ({ children }) => <h4>{children}</h4>,
  'HEADINGS/HEADING-FIVE': ({ children }) => <h5>{children}</h5>,
  'LISTS/UNORDERED-LIST': ({ children }) => <ListIndented>{children}</ListIndented>,
  'LISTS/ORDERED-LIST': ({ children }) => <ListOrdered>{children}</ListOrdered>,
  'LISTS/LIST-ITEM': ({ children }) => <ListItem>{children}</ListItem>,
  'PARAGRAPH/PARAGRAPH': Paragraph,
  'LINK/LINK': Link,
  'LINK/ITEM': Link,
};

const render = (node: Node, index: number, deepElements: DeepElements, deep: boolean) => {
  if (!node || node?.text === '') {
    return null;
  }

  if (!node.type) {
    node.type = node.plugin ?? 'PARAGRAPH/PARAGRAPH';
  }

  if (node.text) {
    const innerText = escapedNewLineToLineBreakTag(node.text);

    const plugins = Object.keys(PLUGIN_ELEMENT_MAP).filter((key) => node[key]);
    if (plugins.length) {
      return plugins.reduce<ReactNode>((acc, plugin) => {
        return React.createElement(PLUGIN_ELEMENT_MAP[plugin], { key: index, data: node.data }, acc);
      }, innerText);
    }

    return innerText;
  }

  const Renderer = PLUGIN_ELEMENT_MAP[node.type];

  return (
    <React.Fragment key={index}>
      {Renderer ? (
        <Renderer data={Renderer ? node.data : null}>
          {node.children?.map((n, i) => render(n, i, deepElements, true))}
        </Renderer>
      ) : (
        <React.Fragment key={index}>{node.children?.map((n, i) => render(n, i, deepElements, true))}</React.Fragment>
      )}
      {node.type === 'PARAGRAPH/PARAGRAPH' && !deep && checkIsBreakable(deepElements) ? <Breakable /> : null}
    </React.Fragment>
  );
};

export function SlateContentPlugin({ cell, deepElements }: ComponentPluginProps) {
  const nodes: Node[] = cell?.content?.state?.slate;
  const size = nodes?.length - 1;

  return <SlateCell nbCol={cell.size}>{nodes?.map((n, i) => render(n, i, deepElements, i === size))}</SlateCell>;
}

export const EmbedSlateContent = ({ data }: { data: Node[] }) => {
  return <SlateContentPlugin cell={{ size: 12, content: { state: data } }} />;
};
