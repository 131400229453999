import React, { useEffect, useState } from 'react';
import ArticleCta from '@marty-js/design/src/molecules/article/cta';
import { useTrackEvent } from '@marty-js/api-sdk/services/matomo';
import { ExternalLink, MinifiedExternalLink } from '@marty-js/design/src/atoms/link';
import type { ComponentPluginProps } from '../types';

import { slugify } from '../../utils/utils';

type contentState = {
  buttonSize: string;
  alignement: string;
  label: string;
  minifiedUrl: string;
  minified: boolean;
};

export function CtaPlugin({ cell }: ComponentPluginProps) {
  const { minifiedUrl, minified, buttonSize, alignement, label }: contentState = cell.content.state;
  const [trackEvent] = useTrackEvent();
  const [refererUrl, setRefererUrl] = useState<string>();

  const eventCta = () => {
    const userToken = localStorage.getItem('actionToken');
    trackEvent({
      variables: {
        userToken,
        eventCategory: 'ItemPage',
        eventName: 'click_cta',
        actionName: 'click_cta',
        eventValue: JSON.stringify({ product: label, price: null, marchand: null, position: null }),
        customDimensions: [],
        customVariables: [],
        url: document.location.href,
      },
    });
  };

  useEffect(() => {
    if (minified && minifiedUrl.includes('clubi.cc')) {
      setRefererUrl(slugify(document.title));
    }
  }, [minified, minifiedUrl]);

  return (
    <ArticleCta
      alignment={alignement}
      onClick={eventCta}
      as={minified ? MinifiedExternalLink : ExternalLink}
      data-testid="cta-button"
      buttonSize={buttonSize}
      referer={refererUrl}
      href={minifiedUrl}
    >
      {label}
    </ArticleCta>
  );
}

export const CTA_PLUGIN_NAME = 'marty/editor/content-plugin/cta';
