import React from 'react';
import dynamic from 'next/dynamic';
import type { ComponentPluginProps } from '../types';

const Faq = dynamic(() => import('@marty-js/design/src/molecules/faq'));

export function FaqPlugin({ cell, children }: ComponentPluginProps) {
  const { question } = cell.layout.state;

  return <Faq question={question}>{children}</Faq>;
}

export const FAQ_PLUGIN_NAME = 'marty/editor/layout-plugin/faq';
