import React, { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import ImageArticle from '@marty-js/design/src/molecules/article/image';
import type { ComponentPluginProps } from '../types';
import { useAddImage, useViewerContext } from '../viewer-context';

const StyledFigure = styled.figure<{ nbCol: number }>`
  grid-column-end: span ${(props) => props.nbCol};
  margin: var(--spacer-s) 0 var(--spacer-fluid) 0;
`;

export function ImageClubicPlugin({ cell }: ComponentPluginProps) {
  const nodeData = useMemo(() => cell.content.state ?? {}, [cell.content.state]);

  const { width = null, height = null } = nodeData;
  const { setShowModal, setCurrentImageId } = useViewerContext();

  const addImage = useAddImage();
  useEffect(() => {
    addImage(nodeData);
    if (nodeData.compare) {
      const imageCompare = {
        width: nodeData.width as number,
        height: nodeData.height as number,
        id: nodeData.idCompare as string,
      };
      addImage(imageCompare);
    }
  }, [nodeData, addImage]);

  const handlerClick = useCallback(() => {
    setCurrentImageId(nodeData.id);
    setShowModal(true);
  }, [setShowModal, setCurrentImageId, nodeData.id]);

  const rel: string[] = [];
  if (nodeData.relNofollow) {
    rel.push('nofollow');
  }

  const reg = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:/\n?]+)/im;
  const whiteListReferer = ['clubic.com', 'clubi.cc', 'jeuxvideo.fr'];
  const matches = reg.exec(nodeData.href);
  if (matches && !whiteListReferer.includes(matches[1])) {
    nodeData.openInNewWindow = true;
    rel.push('noreferrer');
  }

  return (
    <StyledFigure nbCol={cell.size}>
      <ImageArticle
        id={nodeData.id}
        idCompare={nodeData.idCompare}
        compare={nodeData.compare}
        width={width}
        height={height}
        title={nodeData.title}
        titleCompare={nodeData.titleCompare}
        extension={nodeData.extension}
        extensionCompare={nodeData.extensionCompare}
        href={nodeData.href}
        openInNewWindow={nodeData.openInNewWindow}
        rel={rel}
        caption={nodeData.caption}
        handlerClick={handlerClick}
      />
    </StyledFigure>
  );
}

export const IMAGE_CLUBIC_PLUGIN_NAME = 'marty/editor/core/content/image-clubic';
