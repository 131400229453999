import React, { FC, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { mq, DEVICE_SIZE } from '@marty-js/design/src/utils/mq';
import type { Row, Cell, ComponentPlugin, DeepElements, DeepElementType } from './types';
import { IMAGE_CLUBIC_PLUGIN_NAME, ImageClubicPlugin } from './plugins/image-clubic.content';
import { BACKGROUND_COLOR_PLUGIN_NAME, BackgroundColorPlugin } from './plugins/background-color.layout';
import { CONTAINER_PLUGIN_NAME, ContainerPlugin } from './plugins/container.layout';
import { VIDEO_PLUGIN_NAME, VideoContentPlugin } from './plugins/video.content';
import { EXTERNAL_EMBED_PLUGIN_NAME, ExternalEmbedPlugin } from './plugins/embed.content';
import { SLATE_PLUGIN_NAME, SlateContentPlugin } from './plugins/slate.content';
import { ENTITY_BLOCK_PLUGIN_NAME, EntityBlockPlugin } from './plugins/entity-block.content';
import { RATING_BLOCK_PLUGIN_NAME, RatingBlock } from './plugins/entityBlock/rating-block';
import { TECHNICAL_BLOCK_PLUGIN_NAME, TechnicalBlock } from './plugins/entityBlock/technical-block';
import { PRODUCT_CARD_BLOCK_PLUGIN_NAME, ProductCard } from './plugins/entityBlock/product-card';
import { SERVICE_CARD_BLOCK_PLUGIN_NAME, ServiceCard } from './plugins/entityBlock/service-card';
import { READ_MORE_PLUGIN_NAME, ReadMorePlugin } from './plugins/read-more';
import { CTA_PLUGIN_NAME, CtaPlugin } from './plugins/cta';
import { FAQ_PLUGIN_NAME, FaqPlugin } from './plugins/faq.layout';
import { TABS_PLUGIN_NAME, TabsPlugin } from './plugins/tabs.layout';
import { LIVE_PLUGIN_NAME, LivePlugin } from './plugins/live.layout';
import { TABLE_PLUGIN_NAME, TablePlugin } from './plugins/table.content';
import { CLUBIC_EMBED_PLUGIN_NAME, ITEM_EMBED_PLUGIN_NAME, ClubicEmbedPlugin } from './plugins/embed.clubic';
import { FOLDABLE_PLUGIN_NAME, FoldablePlugin } from './plugins/foldable.layout';
import { updataDeepElements, checkIsBreakable } from './plugins/breakableTools';
import Breakable from './breakable';

const RowWrapper = styled.div<{ nbCol: number }>`
  display: flex;
  flex-direction: column;
  gap: 15px;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      flex-direction: row;
    `,
  )}

  > * {
    flex: 1;
  }
`;

export const CellWrapper = styled.div<{
  nbCol: number;
}>`
  grid-column-end: span ${(props) => props.nbCol};
`;

const PLUGINS: Record<string, ComponentPlugin> = {
  [IMAGE_CLUBIC_PLUGIN_NAME]: ImageClubicPlugin,
  [BACKGROUND_COLOR_PLUGIN_NAME]: BackgroundColorPlugin,
  [CONTAINER_PLUGIN_NAME]: ContainerPlugin,
  [LIVE_PLUGIN_NAME]: LivePlugin,
  [VIDEO_PLUGIN_NAME]: VideoContentPlugin,
  [EXTERNAL_EMBED_PLUGIN_NAME]: ExternalEmbedPlugin,
  [ITEM_EMBED_PLUGIN_NAME]: ClubicEmbedPlugin,
  [CLUBIC_EMBED_PLUGIN_NAME]: ClubicEmbedPlugin,
  [SLATE_PLUGIN_NAME]: SlateContentPlugin,
  [ENTITY_BLOCK_PLUGIN_NAME]: EntityBlockPlugin,
  [RATING_BLOCK_PLUGIN_NAME]: RatingBlock,
  [TECHNICAL_BLOCK_PLUGIN_NAME]: TechnicalBlock,
  [PRODUCT_CARD_BLOCK_PLUGIN_NAME]: ProductCard,
  [SERVICE_CARD_BLOCK_PLUGIN_NAME]: ServiceCard,
  [READ_MORE_PLUGIN_NAME]: ReadMorePlugin,
  [CTA_PLUGIN_NAME]: CtaPlugin,
  [FAQ_PLUGIN_NAME]: FaqPlugin,
  [FOLDABLE_PLUGIN_NAME]: FoldablePlugin,
  [TABS_PLUGIN_NAME]: TabsPlugin,
  [TABLE_PLUGIN_NAME]: TablePlugin,
};

type CellProps = {
  cell: Cell;
  deepElements: DeepElements;
};

type RowProps = {
  row: Row;
  nbCol?: number;
  deepElements: DeepElements;
};

const Editor: { Cell?: FC<CellProps>; Row?: FC<RowProps> } = {};

export const EditoCell: FC<CellProps> = ({ cell, deepElements }) => {
  const newDeepElements = useMemo(() => {
    let type: DeepElementType = 'cell';
    if (cell.content) {
      type = 'cell:content';
    } else if (cell.layout) {
      type = 'cell:layout';
    }

    return updataDeepElements(deepElements, {
      type,
      plugin: cell.content?.plugin?.name ?? cell.layout?.plugin?.name,
    });
  }, [deepElements, cell]);

  if (cell.content) {
    const PluginComponent = PLUGINS[cell.content.plugin.name];

    if (PluginComponent) {
      return <PluginComponent cell={cell} deepElements={newDeepElements} />;
    }

    return null;
  }

  if (cell.layout) {
    const PluginComponent = PLUGINS[cell.layout.plugin.name];
    if (PluginComponent) {
      return (
        <PluginComponent cell={cell} deepElements={newDeepElements}>
          {cell.rows?.map((innerRow) => (
            <Editor.Row nbCol={cell.size} row={innerRow} key={innerRow.id} deepElements={newDeepElements} />
          ))}
        </PluginComponent>
      );
    }
  }

  return (
    <CellWrapper nbCol={cell.size}>
      {cell.rows?.map((innerRow) => (
        <Editor.Row nbCol={cell.size} row={innerRow} key={innerRow.id} deepElements={newDeepElements} />
      ))}
    </CellWrapper>
  );
};

Editor.Cell = EditoCell;

export const EditoRow: FC<RowProps> = ({ row, nbCol = 12, deepElements }) => {
  const newDeepElements = useMemo(
    () => updataDeepElements(deepElements, { type: 'row', nbCol }),
    [deepElements, nbCol],
  );

  const isBreakable = useMemo(() => checkIsBreakable(newDeepElements), [newDeepElements]);

  return (
    <>
      {isBreakable ? <Breakable /> : null}
      <RowWrapper nbCol={nbCol} className="row">
        {row.cells?.map((cell) => (
          <Editor.Cell cell={cell} key={cell.id} deepElements={newDeepElements} />
        ))}
      </RowWrapper>
    </>
  );
};

Editor.Row = EditoRow;
