import React from 'react';
import { HeadingTwo as HeadingTwoDesign } from '@marty-js/design/src/atoms/h2';
import type { HeadingData, PluginComponent } from './types';
// import { AdsRenderContext } from '../../ads-context';

export const HeadingTwo: PluginComponent = ({ children, data }) => {
  const headingData = data as HeadingData;

  /*
  const adsHeadingRenderedContext = useContext(AdsRenderContext);
  adsHeadingRenderedContext.increment();
  const ads =
    adsHeadingRenderedContext.nbRender in adsHeadingRenderedContext.ads
      ? adsHeadingRenderedContext.ads[adsHeadingRenderedContext.nbRender]
      : null;

      {ads}
*/
  return <HeadingTwoDesign id={headingData?.index}>{children}</HeadingTwoDesign>;
};
