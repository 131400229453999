import React from 'react';
import styled from 'styled-components';
import type { ComponentPluginProps } from '../types';

// For legacy editor
const Wrapper = styled.div<{
  nbCol: number;
  noPadding?: boolean;
  backgroundTheme?: 'light' | 'contrast' | 'transparent' | 'flashy';
}>`
  margin: var(--spacer-fluid) 0;
  padding: var(--spacer-fluid);
  border: solid 1px #ddd;
  border-radius: 6px;

  &.transparent {
    background: none;
    border: none;
    padding: 0 var(--spacer-l);
  }

  &.contrast {
    background-color: var(--theme-palette-blue);
    border: none;
    color: white;
  }

  &.flashy {
    background-color: var(--theme-palette-red);
    border: none;
    color: white;
  }

  .row {
    margin-bottom: 0;
  }
`;

Wrapper.defaultProps = {
  backgroundTheme: 'light',
  noPadding: true,
};

export function BackgroundColorPlugin({ cell, children }: ComponentPluginProps) {
  return (
    <Wrapper nbCol={cell.size} className={cell.layout?.state?.colorSlug} noPadding={!!cell.layout?.state?.noPadding}>
      {children}
    </Wrapper>
  );
}

export const BACKGROUND_COLOR_PLUGIN_NAME = 'marty/editor/core/layout/background-color';
