import React, { useCallback } from 'react';
import dynamic from 'next/dynamic';
import { useTrackEvent } from '@marty-js/api-sdk/services/matomo';
import type { ComponentPluginProps } from '../types';

const EmbedPlugin = dynamic(() => import('@marty-js/design/src/molecules/embed/embed'));

export function ExternalEmbedPlugin({ cell }: ComponentPluginProps) {
  const [trackEvent] = useTrackEvent();
  const src = cell?.content?.state?.src;

  const eventCta = useCallback(
    (shopName?: string, price?: string, position?: string, label?: string) => {
      const userToken = localStorage.getItem('actionToken');

      trackEvent({
        variables: {
          userToken,
          eventCategory: 'ItemPage',
          eventName: 'click_cta',
          actionName: 'click_cta',
          eventValue: JSON.stringify({
            product: label ?? '',
            price: price ?? '',
            marchand: shopName ?? '',
            position: position ?? null,
          }),
          customDimensions: [],
          customVariables: [],
          url: document.location.href,
        },
      });
    },
    [trackEvent],
  );

  if (!src) {
    return null;
  }

  if (cell?.content?.state) {
    if (cell?.content?.state?.isAffilizz) {
      cell.content.state.eventCta = eventCta;
    }

    return <EmbedPlugin {...cell.content.state} />;
  }

  return null;
}

export const EXTERNAL_EMBED_PLUGIN_NAME = 'marty/editor/core/content/embed';
