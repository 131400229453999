import React from 'react';

const Breakable = () => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: `<div class="div-breakable"></div>`,
      }}
    />
  );
};

export default Breakable;
