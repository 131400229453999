import React from 'react';
import { Faq } from '@marty-js/design/src/molecules/faq';
import type { ComponentPluginProps } from '../types';

export function FoldablePlugin({ cell, children }: ComponentPluginProps) {
  const { title } = cell.layout.state;

  return <Faq question={title}>{children}</Faq>;
}

export const FOLDABLE_PLUGIN_NAME = 'marty/editor/core/layout/foldable';
