import React, { PropsWithChildren, useMemo } from 'react';
import styled, { css } from 'styled-components';
import dynamic from 'next/dynamic';
import { DEVICE_SIZE, mq } from '@marty-js/design/src/utils/mq';
import { baseStyle } from './edito-content-style';
import { EditoRow } from './edito-cell-row';
import type { Cell, Link } from './types';
import { ViewerProvider } from './viewer-context';

const IndexContent = dynamic(() => import('./index-content'));

const Content = styled.article`
  ${baseStyle};

  > *:first-child * {
    margin-top: 0;
  }
`;

const GridDefault = styled.div`
  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      margin: 0 60px;
    `,
  )}

  &:first-of-type p > strong {
    font-size: 18px;
    line-height: 1.5em;
  }
`;

const GridWide = (props: React.HTMLAttributes<HTMLDivElement>) => <div {...props} />;

const NavItemIndex = styled.div`
  margin: var(--spacer-s-fluid) 0;
  position: sticky;
  top: 0;
  z-index: 1000002;

  &::before {
    background-color: var(--theme-color-background);
    bottom: 0;
    content: '';
    left: -20px;
    position: absolute;
    right: -20px;
    top: 0;
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      top: 20px;
      z-index: 2;

      &::before {
        content: initial;
      }
    `,
  )}
`;
interface Props {
  articleJson: Cell[];
  links?: Link[];
  firstTitleRow?: string;
  main?: boolean;
}

const KeyWrapper: React.FC<PropsWithChildren<any>> = ({ children }) => {
  return children;
};

export function EditoGrid({ articleJson, links, firstTitleRow, main = true }: Props) {
  const articleContent = useMemo<Cell[]>(() => articleJson || null, [articleJson]);
  const deepElements = useMemo(() => [], []);

  return (
    <ViewerProvider>
      <Content className={main ? 'main-content' : ''}>
        {articleContent?.map((cell) => {
          return cell.rows.map((row) => {
            // row.cells.layout dans certains plugins
            // Pour que ce soit widable il faut que ce soit dans un conteneur
            // Par défaut les rows ne sont pas Widable
            // Chaque cell est stretch
            // console.log(row.cells?.layout);

            const RowType =
              Array.isArray(row?.cells) &&
              (row?.cells[0]?.layout?.state?.row?.gridPosition === 'wide' ||
                row?.cells[0]?.content?.state?.row?.gridPosition === 'wide')
                ? GridWide
                : GridDefault;
            // Gérer le nowrap mobile

            return !firstTitleRow || firstTitleRow !== row.id ? (
              <RowType key={row.id}>
                <EditoRow row={row} key={row.id} deepElements={deepElements} />
              </RowType>
            ) : (
              <KeyWrapper key={row.id}>
                <NavItemIndex>
                  <IndexContent links={links} />
                </NavItemIndex>
                <RowType>
                  <EditoRow row={row} key={row.id} deepElements={deepElements} />
                </RowType>
              </KeyWrapper>
            );
          });
        })}
      </Content>
    </ViewerProvider>
  );
}
