import React from 'react';
import { Panel } from '@marty-js/design/src/molecules/panel';
import type { ComponentPluginProps } from '../types';

export function TabsPlugin({ cell, children }: ComponentPluginProps) {
  const { tabs } = cell.layout.state;

  return <Panel tabs={tabs}>{children}</Panel>;
}

export const TABS_PLUGIN_NAME = 'marty/editor/core/layout/tabs';
