import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { DEVICE_SIZE, mq } from '@marty-js/design/src/utils/mq';
import { useTranslation } from '@marty-js/design/src/utils/translation';
import { useThemeSwitcher } from '@marty-js/design/src/utils/theme-switcher';
import { UndecoratedNavLink } from '@marty-js/design/src/atoms/link';
import { isMobile } from '../utils/utils';
import { Link } from './types';

const ArrowDown = dynamic(() => import('@marty-js/design/src/icons/arrowDown'));
const ArrowPlainDown = dynamic(() => import('@marty-js/design/src/icons/arrowPlainDown'));

const Wrapper = styled.nav`
  background: var(--theme-color-background);
  border-radius: 6px;
  box-shadow: 0px 0px 30px 0px #0000001a;
  position: relative;
  display: flex;
  z-index: 2;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      margin-bottom: 0;
    `,
  )}
`;

const CollapseHeading = styled.button`
  background: var(--theme-color-background);
  border: solid 1px var(--theme-palette-blue-medium);
  border-radius: 6px;
  display: flex;
  font-family: var(--theme-typography-primary-font);
  justify-content: space-between;
  font-weight: 700;
  padding: 15px;
  text-align: left;
  font-size: 22px;
  width: 100%;

  &.mod-open {
    border-bottom: solid 1px #eeeeee;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    z-index: 10;
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      padding: 15px 30px;
    `,
  )}
`;

const CollapseHeadingLabel = styled.span`
  line-height: 30px;
`;

const CollapseHeadingArrow = styled.span<{ isCollapsed: boolean }>`
  transition: transform 0.3s;

  ${(props) =>
    props.isCollapsed
      ? null
      : css`
          transform: rotate(180deg);
          position: relative;
          top: 2px;
        `}
`;

const List = styled.ul`
  background: var(--theme-color-background);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  box-shadow: 0px 0px 30px 0px #0000001a;
  display: flex;
  flex-direction: column;
  gap: 17px;
  list-style: none;
  max-height: calc(100vh - 62px);
  overflow-y: auto;
  position: absolute;
  top: 62px;
  width: 100%;

  &.mod-open {
    border-left: solid 1px var(--theme-palette-blue-medium);
    border-bottom: solid 1px var(--theme-palette-blue-medium);
    border-right: solid 1px var(--theme-palette-blue-medium);
    padding: 15px 15px 30px;
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      border: none;
      max-height: initial;
      overflow-y: initial;

      &.mod-open {
        padding: 17px 30px 30px;
        overflow-y: auto;
        max-height: calc(100vh - 240px);
      }
    `,
  )}
`;

const ListItem = styled.li`
  display: none;
  flex: 1 0 auto;

  &.unfolded {
    display: flex;
  }
`;

const ItemLink = styled.button`
  display: flex;
  font-size: 16px;
  gap: 16px;
  height: 100%;
  text-align: left;
  width: 100%;
  && {
    color: var(--text-color);

    &:hover,
    &.current {
      color: var(--theme-color-primary);
    }
    &.sub {
      padding-left: 38px;
      font-size: 14px;
    }
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      font-size: 18px;
    `,
  )}
`;

const ItemLinkEllipse = styled.div`
  align-items: center;
  background: var(--theme-palette-dark-white);
  border-radius: 50%;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
  min-width: 24px;
  min-height: 24px;
`;

interface Props {
  links: Array<Link>;
  className?: string;
}
export const IndexContent = React.forwardRef<HTMLElement, Props>(({ links, className }, ref) => {
  const [currentLinkActive, setCurrentLinkActive] = useState(null);
  const [collapsed, setCollapsed] = useState(true);
  const { currentTheme } = useThemeSwitcher();
  const arrowColor = useMemo<string>(() => {
    return currentTheme.backgroundTheme.contrast.textColor;
  }, [currentTheme]);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const scrollToElement = (event: Event, item: Link) => {
    event.preventDefault();
    setCollapsed(!collapsed);

    const headerHeight = isMobile() ? 60 : 80 + 20;
    const top = document.querySelector(item.href).getBoundingClientRect().top + window.scrollY - headerHeight;
    window.scroll({ behavior: 'smooth', top });
  };

  const t = useTranslation();

  useEffect(() => {
    const observer: IntersectionObserver = new window.IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = links.findIndex((link) => link.href && document.querySelector(link.href) === entry.target);
            setCurrentLinkActive(index);
          }
        });
      },
      {
        rootMargin: '0px 0px -50% 0px',
        threshold: 1,
      },
    );

    const anchorsObserver: Array<Element> = [];

    links.forEach((link) => {
      if (link.href) {
        const anchor = document.querySelector(link.href);
        if (anchor) {
          observer.observe(anchor);
          anchorsObserver.push(anchor);
        }
      }
    });

    return () => {
      anchorsObserver.forEach((anchor: Element) => {
        observer.unobserve(anchor);
      });
    };
  }, [links]);

  return (
    <Wrapper data-testid="index-content" ref={ref} className={className}>
      <CollapseHeading className={collapsed ? '' : 'mod-open'} onClick={toggleCollapse}>
        <CollapseHeadingLabel>{t('design.itemDefault.index')}</CollapseHeadingLabel>
        <CollapseHeadingArrow isCollapsed={collapsed}>
          <ArrowDown color={arrowColor} height={7} width={12} />
        </CollapseHeadingArrow>
      </CollapseHeading>
      <List className={collapsed ? '' : 'mod-open'}>
        {links.map((item, index) => (
          <ListItem
            key={item.label}
            className={classNames({
              unfolded: !collapsed,
            })}
          >
            {(item as Link).href ? (
              <ItemLink
                className={classNames({
                  current: index === currentLinkActive,
                  sub: item.deepness === 2,
                })}
                href={(item as Link).href}
                onClick={(e: Event) => scrollToElement(e, item as Link)}
                as={UndecoratedNavLink}
              >
                <ItemLinkEllipse>
                  <ArrowPlainDown color="var(--theme-palette-dark-grey-blue)" height={11} width={8} />
                </ItemLinkEllipse>
                {item.label}
              </ItemLink>
            ) : null}
          </ListItem>
        ))}
      </List>
    </Wrapper>
  );
});

export default IndexContent;
