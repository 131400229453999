import React from 'react';
import ContainerArticle from '@marty-js/design/src/molecules/article/container';
import type { ComponentPluginProps } from '../types';

export function ContainerPlugin({ cell, children }: ComponentPluginProps) {
  if (!cell?.layout?.state) {
    return null;
  }

  return <ContainerArticle className={cell.layout.state.colorSlug ?? 'transparent'}>{children}</ContainerArticle>;
}

export const CONTAINER_PLUGIN_NAME = 'marty/editor/core/layout/container';
