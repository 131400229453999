import React from 'react';
import dynamic from 'next/dynamic';
import type { ComponentPluginProps } from '../types';

const ProductCard = dynamic(() => import('./entityBlock/product-card'));
const RatingBlock = dynamic(() => import('./entityBlock/rating-block'));

export function EntityBlockPlugin({ cell }: ComponentPluginProps) {
  const { type } = cell.content.state;

  switch (type) {
    case 'product-card':
      return <ProductCard cell={cell} />;
    case 'rating-block':
      return <RatingBlock cell={cell} />;
    default:
      return null;
  }
}

export const ENTITY_BLOCK_PLUGIN_NAME = 'marty/editor/core/content/entity-block';
