import React from 'react';
import dynamic from 'next/dynamic';
import type { Cell } from '../../types';

interface ClubicContainerProps {
  cell: Cell;
}

const TechnicalBlockArticle = dynamic(() => import('@marty-js/design/src/molecules/article/technical-block'));

export function TechnicalBlock({ cell }: ClubicContainerProps) {
  return <TechnicalBlockArticle cell={cell} />;
}

export const TECHNICAL_BLOCK_PLUGIN_NAME = 'marty/editor/core/content/technical-block';
