import { css } from 'styled-components';

export const baseStyle = css`
  font-size: 16px;
  line-height: 1.62;

  @media (min-width: 1024px) {
    font-size: 18px;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    padding: 0;
  }
`;
