import type { DeepElement, DeepElements } from '../types';

export const updataDeepElements = (deepElements: DeepElements, deepElement: DeepElement) => {
  const lastElement = deepElements.slice(-1)[0];
  if (lastElement && (!deepElement.nbCol || deepElement.nbCol > lastElement.nbCol)) {
    deepElement.nbCol = lastElement.nbCol;
  }
  const newDeepElements = JSON.parse(JSON.stringify(deepElements));
  newDeepElements.push(deepElement);

  return newDeepElements;
};

export const checkIsBreakable = (deepElements: DeepElements): boolean => {
  let deepElement;
  if (!deepElements) {
    return false;
  }

  for (let i = 0; i < deepElements.length; i += 1) {
    deepElement = deepElements[i];
    if (deepElement.type === 'cell:layout') {
      return false;
    }
  }

  if (deepElement.nbCol !== 12) {
    return false;
  }

  return true;
};
