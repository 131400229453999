import React from 'react';
import dynamic from 'next/dynamic';
import type { ComponentPluginProps } from '../types';

const ClubicEmbed = dynamic(() => import('@marty-js/design/src/molecules/embed/clubic-embed'));

export function ClubicEmbedPlugin({ cell }: ComponentPluginProps) {
  return <ClubicEmbed {...cell.content.state} />;
}

export const CLUBIC_EMBED_PLUGIN_NAME = 'marty/editor/core/content/clubic-embed';
export const ITEM_EMBED_PLUGIN_NAME = 'marty/editor/core/content/item-embed';
