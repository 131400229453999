import React from 'react';
import styled from 'styled-components';
import dynamic from 'next/dynamic';
import { useThemeSwitcher } from '@marty-js/design/src/utils/theme-switcher';
import type { ComponentPluginProps } from '../types';

const Player = dynamic(() => import('@marty-js/design/src/molecules/article/player'));

const Play = dynamic(() => import('@marty-js/design/src/icons/play'));
const MediaCell = styled.div<{ nbCol: number }>`
  margin: var(--spacer) 0;
`;

const EmptyPlayerWrapper = styled.div`
  align-items: center;
  display: flex;
  padding-top: calc(50% / (16 / 9) - 60px);
  padding-bottom: calc(50% / (16 / 9) - 60px);
  justify-content: center;
`;

export function VideoContentPlugin({ cell }: ComponentPluginProps) {
  const { currentTheme } = useThemeSwitcher();

  if (!cell?.content?.state?.src) {
    return null;
  }

  const { src } = cell.content.state;

  return src ? (
    <MediaCell nbCol={cell.size}>
      <Player src={src} />
    </MediaCell>
  ) : (
    <MediaCell nbCol={cell.size}>
      <EmptyPlayerWrapper>
        <Play color={currentTheme.palette.white} height={60} width={60} />
      </EmptyPlayerWrapper>
    </MediaCell>
  );
}

export const VIDEO_PLUGIN_NAME = 'marty/editor/core/content/video';
