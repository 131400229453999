import React, { useCallback } from 'react';
import dynamic from 'next/dynamic';
import { useTrackEvent } from '@marty-js/api-sdk/services/matomo';
import type { Cell } from '../../types';
import { EmbedSlateContent } from '../slate.content';

const RatingCard = dynamic(() => import('@marty-js/design/src/molecules/cards/product/rating'));
interface ClubicContainerProps {
  cell: Cell;
}

export function RatingBlock({ cell }: ClubicContainerProps) {
  const [trackEvent] = useTrackEvent();

  const title = cell?.content?.state?.data?.title;
  const eventCta = useCallback(
    (shopName?: string, price?: string, position?: string, itemId?: number) => {
      const userToken = localStorage.getItem('actionToken');

      trackEvent({
        variables: {
          userToken,
          eventCategory: 'BestPickPage',
          eventName: 'click_comparatif',
          actionName: 'click_comparatif',
          eventValue: JSON.stringify({
            product: title,
            price,
            marchand: shopName,
            position: position ?? null,
            itemId: itemId ?? null,
          }),
          customDimensions: [],
          customVariables: [],
          url: window.location.pathname,
        },
      });
    },
    [title, trackEvent],
  );

  if (!cell.content.state?.data) {
    return null;
  }

  return <RatingCard cell={cell} eventCta={eventCta} SlateContent={EmbedSlateContent} />;
}

export default RatingBlock;

export const RATING_BLOCK_PLUGIN_NAME = 'marty/editor/core/content/rating-block';
