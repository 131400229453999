import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import dynamic from 'next/dynamic';
import { DEVICE_SIZE, mq } from '@marty-js/design/src/utils/mq';
import { baseStyle } from './edito-content-style';
import { EditoRow, CellWrapper } from './edito-cell-row';
import type { Cell, DeepElements, Link } from './types';

const IndexContent = dynamic(() => import('./index-content'));

const Content = styled.article`
  display: flex;
  column-gap: 8px;
  align-items: start;
  ${baseStyle};
`;

interface Props {
  articleJson: Cell[];
  links?: Link[];
  firstTitleRow?: string;
}

const NavItemIndex = styled.div`
  margin: -28px -18px 28px;
  position: sticky;
  top: 0;
  z-index: 100;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      margin: 28px 0 28px;
      top: 77px;
      z-index: 2;
    `,
  )}
`;

export function EditoContent({ articleJson, links, firstTitleRow }: Props) {
  const articleContent = useMemo<Cell[]>(() => articleJson || null, [articleJson]);
  const deepElements: DeepElements = useMemo(() => [], []);

  return (
    <Content>
      {articleContent?.map((cell) => (
        <CellWrapper key={cell.id} nbCol={cell.size}>
          {cell.rows.map((row) => {
            return !firstTitleRow || firstTitleRow !== row.id ? (
              <EditoRow row={row} key={row.id} deepElements={deepElements} />
            ) : (
              <>
                <NavItemIndex>
                  <IndexContent links={links} />
                </NavItemIndex>
                <EditoRow row={row} key={row.id} deepElements={deepElements} />
              </>
            );
          })}
        </CellWrapper>
      ))}
    </Content>
  );
}
