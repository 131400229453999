import React from 'react';
import { Table } from '@marty-js/design/src/molecules/table';
import type { ComponentPluginProps } from '../types';

export function TablePlugin({ cell }: ComponentPluginProps) {
  const { data } = cell.content.state;

  return <Table data={data} />;
}

export const TABLE_PLUGIN_NAME = 'marty/editor/core/content/table';
