import React, { PropsWithChildren, useCallback, useContext, useMemo, useState } from 'react';

import dynamic from 'next/dynamic';

const ViewerModal = dynamic(() => import('@marty-js/design/src/molecules/viewer-modal'));

type Image = {
  width: number;
  height: number;
  id: string;
};
type ViewerContextType = {
  showModal: boolean;
  setShowModal: (val: boolean) => void;
  setCurrentImageId: (val: string) => void;
  images: Image[];
  imageIds: string[];
  currentImageId: string;
};
export const ViewerContext = React.createContext<ViewerContextType>({
  showModal: false,
  images: [],
  imageIds: [],
  setShowModal: () => {},
  setCurrentImageId: () => {},
  currentImageId: null,
});

export const useViewerContext = () => {
  const context = useContext(ViewerContext);

  return context;
};

export const useAddImage = () => {
  const context = useContext(ViewerContext);

  return useCallback(
    (image: Image) => {
      if (!context.imageIds.includes(image.id)) {
        context.imageIds.push(image.id);
        context.images.push(image);
      }
    },
    [context.images, context.imageIds],
  );
};

export const Modal = () => {
  const { showModal, images, setShowModal, currentImageId } = useViewerContext();
  if (showModal) {
    return <ViewerModal images={images} callbackClose={() => setShowModal(false)} currentImageId={currentImageId} />;
  }

  return null;
};
export function ViewerProvider({ children }: PropsWithChildren<{}>) {
  const [showModal, setShowModal] = useState(false);
  const [currentImageId, setCurrentImageId] = useState(null);
  const images = useMemo(() => [], []);
  const imageIds = useMemo(() => [], []);
  const context = useMemo(
    () => ({
      images,
      imageIds,
      showModal,
      setShowModal,
      setCurrentImageId,
      currentImageId,
    }),
    [showModal, setShowModal, currentImageId, setCurrentImageId, images, imageIds],
  );

  return (
    <ViewerContext.Provider value={context}>
      {children}
      <Modal />
    </ViewerContext.Provider>
  );
}
